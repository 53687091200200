<template>
  <div class="tag-manage main-cnt">
    <div class="title">标签列表</div>
    <div class="content">
      <common-table
        ref="tagTable"
        tableHeight="calc(100vh - 320px)"
        :ischeck="false"
        :ispaging="true"
        :apiName="HotelApi.getLableList"
        :columns="tableColumns"
        @statusChange="statusChange"
        @edit="tagEdit"
      >
        <template #operate>
          <el-button
            type="primary"
            round
            @click="showAddDialog"
            v-if="authData.indexOf('h_XKj4FjKUT1W5Bk6RaM4Bw4Z7111') != -1"
          >
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增标签</el-button
          >
        </template>
      </common-table>
    </div>

    <!-- 新增/编辑房型弹框 -->
    <w-dialog
      ref="addDialog"
      class="add-dialog"
      :title="isEdit ? '编辑标签' : '新增标签'"
      width="40%"
      btnWidth="140px"
      top="20vh"
      :confirmText="isEdit ? '确认编辑' : '确认新增'"
      @wConfirm="tagConfirm"
    >
      <el-form
        class="add-form"
        ref="addForm"
        :model="ruleForm"
        :rules="rules"
        labelPosition="top"
      >
        <el-form-item label="标签名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入标签名称"
          ></el-input>
        </el-form-item>

        <!-- 图片上传部分 -->
        <el-form-item class="upload" label="标签图标" prop="tagIcon">
          <img-upload
            uploadTitle="标签图标"
            :limit="1"
            :fileList="fileList"
            @uploadFile="uploadFile"
            @deleteFile="deleteFile"
          ></img-upload>
        </el-form-item>
      </el-form>
    </w-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed, watch } from "vue";
// 导入图片上传组件
import imgUpload from "@/components/img-upload/img-upload.vue";
import { HotelApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";

const store = useStore();
const menuTokens = computed(() => store.state.menuToken.menuTokens);
const authData = ref([]);
watch(
  () => menuTokens.value,
  (data) => {
    if (data.length) {
      authData.value = data;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
/** 文件访问域名 */
// const fileUrl = computed(() => store.state.Config.fileUrl);

/** 表格对象 */
const tagTable = ref(null);
/** 表格配置数据 */
const tableColumns = ref([
  {
    prop: "hrl_name",
    label: "标签名称",
    minWidth: 120,
    color: "--text-color",
  },
  {
    type: "image",
    prop: "hrl_icon_url",
    label: "标签图标",
    minWidth: 120,
  },
  {
    type: "switch",
    prop: "hrl_status",
    label: "标签状态",
    minWidth: 120,
    token: "h_XKj4FjKUT1W5Bk6RaM4Bw4Z7333",
  },
  {
    prop: "creator",
    prop2: "u_name",
    label: "创建人",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    prop: "hrl_ctime",
    label: "创建时间",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 100,
    bottons: [
      {
        name: "编辑",
        action: "edit",
        token: "h_XKj4FjKUT1W5Bk6RaM4Bw4Z7222",
        className: "theme-font-btn",
      },
    ],
  },
]);
/** 当前 行数据 */
const currentRow = ref(null);
/** 状态改变处理 */
const statusChange = (row) => {
  let data = {
    hrl_id: row.hrl_id,
    hrl_status: row.hrl_status == 1 ? 2 : 1,
  };
  HotelApi.setLableStatus(data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("标签状态修改成功！");
      // 重新获取标签列表数据
      tagTable.value.tableLoad();
    } else {
      let msg = res.Message ? res.Message : "标签状态修改失败！";
      ElMessage.error(msg);
    }
  });
};
/** 表格编辑 */
const tagEdit = (row) => {
  currentRow.value = row;
  ruleForm.name = row.hrl_name;
  ruleForm.tagIcon = row.hrl_icon_url;
  fileList.value = [{ file_url: row.hrl_icon_url }];
  isEdit.value = true;
  addDialog.value.show();
};

/** 新增/编辑弹框 */
const addDialog = ref(null);
/** 当前是否是编辑操作 */
const isEdit = ref(false);
/** 表单对象 */
const addForm = ref(null);
/** 表单数据对象 */
const ruleForm = reactive({
  name: "", // 标签名称
  tagIcon: "", // 标签图标
});
/** 表单规则对象 */
const rules = reactive({
  name: [
    {
      required: true,
      message: "请输入标签名称",
      trigger: "blur",
    },
  ],
  tagIcon: [
    {
      required: true,
      message: "请上传标签图标",
      trigger: "change",
    },
  ],
});
/** 图标列表 */
const fileList = ref([]);
/** 弹出 新增/编辑房型 弹框 */
const showAddDialog = () => {
  isEdit.value = false;
  // 表单验证重置
  if (addForm.value) {
    addForm.value.resetFields();
  }
  ruleForm.name = "";
  ruleForm.tagIcon = "";

  fileList.value = [];
  store.dispatch("getQiniuData");
  addDialog.value.show();
};
/** 图片上传 */
const uploadFile = (obj) => {
  fileList.value = [{ file_url: obj.standard_url }];
  ruleForm.tagIcon = obj.key;
  addForm.value.validateField("tagIcon");
};
/** 图片删除 */
const deleteFile = () => {
  fileList.value = [];
  ruleForm.tagIcon = "";
  addForm.value.validateField("tagIcon");
};
/** 新增/编辑 确认提交 */
const tagConfirm = () => {
  addForm.value.validate((valid) => {
    if (valid) {
      addDialog.value.isLoading = true;
      let data = {
        hrl_name: ruleForm.name,
        hrl_icon: ruleForm.tagIcon,
      };
      let url = "addLable";
      if (isEdit.value) {
        url = "editLable";
        data.hrl_id = currentRow.value.hrl_id;
      }
      HotelApi[url](data).then((res) => {
        addDialog.value.isLoading = false;
        const text = isEdit.value ? "修改" : "新增";
        if (res.Code === 200) {
          ElMessage.success(`标签${text}成功！`);
          addDialog.value.close();
          // 重新获取标签列表数据
          tagTable.value.tableLoad();
        } else {
          let msg = res.Message ? res.Message : `标签${text}失败！`;
          ElMessage.error(msg);
        }
      });
    }
  });
};

onMounted(() => {
  tagTable.value.tableLoad();
});
</script>

<style lang="scss">
.tag-manage {
  font-family: "Source Han Sans CN";
  .content {
    padding: 15px 20px 20px;
  }
  .add-dialog {
    .el-dialog {
      min-width: 600px;
      .el-dialog__body {
        padding: 0 15px 30px;
        .add-form {
          display: flex;
          flex-wrap: wrap;
          .el-form-item {
            width: 50%;
            padding: 30px 15px 0;
            margin-bottom: 0;
            .el-form-item__content {
              .el-input {
                width: 100%;
              }
            }
          }
          .el-form-item.upload {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
